import dromo from '../../assets/images/games/dromo.png'
import bingo from '../../assets/images/games/bingo.png'
import happy from '../../assets/images/games/mrhappy.png'
import flow from '../../assets/images/games/overflow.png'
import sokoo from '../../assets/images/games/sokooo.png'
import twadi from '../../assets/images/games/twadi.png'
import cashout from '../../assets/images/games/CASHOUT.png'
import alarm from '../../assets/images/alarm.png'
import resultStars from '../../assets/images/web-app-results-stars.png'
import playNowBg from '../../assets/images/web-app-play-now-stars.png'
import { useQuery, gql } from '@apollo/client'
import { Link } from 'react-router-dom'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
// import { Menu, X } from 'react-feather'
import dayjs from 'dayjs'
import Timer from '../../components/Timer'
import axios from 'axios'
import { getImages } from '../../auth/userSlice'
import { useDispatch } from 'react-redux'

// import dayjs from "dayjs";

// import { useState } from "react";

const GET_TODAY_GAME = gql`
  query gameOfTheDay($schedule_day: jsonb) {
    game(
      limit: 1
      order_by: [{ schedule_start: asc }, { run_end: asc }]
      where: {
        is_active: { _eq: true }
        schedule_start: { _lte: now }
        schedule_end: { _gte: now }
        product: { schedule_day: { _contains: $schedule_day } }
      }
    ) {
      id
      results
      schedule_start
      schedule_end
      run_start
      run_end
      event_code
      claim_expiry_hours
      is_active
      product {
        id
        name
        image
        description
        slogan
        min_stake_amount
        max_stake_amount
        schedule_start
        schedule_end
        schedule_day
        schedule_type
        is_active
        properties
      }
    }
  }
`

const GET_LAST_GAME = gql`
  query getLastGame {
    last_game: game(
      limit: 1
      order_by: { closed_at: desc }
      where: {
        is_active: { _eq: false }
        closed_at: { _is_null: false }
        results: { _neq: [] }
      }
    ) {
      id
      results
      schedule_start
      schedule_end
      run_start
      run_end
      event_code
      claim_expiry_hours
      created_at
      is_active
      product {
        id
        name
        image
        description
        slogan
        min_stake_amount
        max_stake_amount
        schedule_start
        schedule_end
        schedule_day
        schedule_type
        is_active
        properties
      }
    }
  }
`

const GET_GAMES = gql`
  query MyQuery {
    product(where: {owner: {name: {_eq: "Fortune Synergy"}}}) {
      id
      image
      description
      name
      slogan
      schedule_end
      schedule_start
      schedule_day
    }
  }
`

const DashHome = () => {
  const { data } = useQuery(GET_LAST_GAME)
  const [productsData, setProductsData] = useState(null)
  const [disableGame, setDisableGame] = useState(false)
  const [gameofDay, setGameofDay] = useState(null)
  const [initloading, setInitLoading] = useState(true)

  const { loading } = useQuery(GET_GAMES, {
    onCompleted: data => {
      console.log(sortDays(data))
      setProductsData(sortDays(data))
    }
  })

  useEffect(() => {
    let timeNow = Date.now()
    let timeLimit = new Date()
    timeLimit.setHours(19, 0, 0)

    if (timeNow >= timeLimit) {
      setDisableGame(true)
    }
  }, [])

  const sortDays = results => {
    // console.log(results)

    let newResults = [...results?.product]

    const sortedData = newResults.sort((a, b) => {
      const dayA = a?.schedule_day[0]
      const dayB = b?.schedule_day[0]

      // Define the order of the days of the week
      const daysOfWeek = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat']

      // Compare the position of the days in the daysOfWeek array
      const positionA = daysOfWeek.indexOf(dayA)
      const positionB = daysOfWeek.indexOf(dayB)

      return positionA - positionB
    })

    // console.log(sortedData)

    return sortedData
  }

  const formatDate = date => {
    return dayjs(date).format('MMMM D, YYYY')
  }

  // const [currentDay, setCurrentDay] = useState("");
  let d = moment().weekday()
  let todayDate =
    d === 2
      ? 'tue'
      : d === 3
      ? 'wed'
      : d === 4
      ? 'thu'
      : d === 5
      ? 'fri'
      : d === 6
      ? 'sat'
      : d === 0
      ? 'sun'
      : 'mon'

  const { data: today } = useQuery(GET_TODAY_GAME, {
    variables: {
      schedule_day: [todayDate]
    }
  })

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getImages())
  }, [dispatch])


  const getGameOfDay = async () =>{
    try{
      let response = await axios.get("https://backend.megafortunelottery.com/api/v1/game/today")
      console.log(response)
      if(response.status === 200 && response?.data?.success === true){
        setInitLoading(false)
        localStorage.setItem("gameData", JSON.stringify(response?.data?.data[0]));
        console.log(response?.data?.data[0]?.product.image)
        setGameofDay(response?.data?.data[0])
      }
      else{
        setInitLoading(false)
        throw "Something went wrong"
      }
    }
    catch(error){
      setInitLoading(false)
      throw error
    }
  }

  useEffect(()=>{
    getGameOfDay()
  },[])

  return (
    <div className='py-4 px-[5%]'>
      <div className='flex md:flex-row md:justify-between flex-col lg:h-[248px] xl:h-[344px] md:h-[200px] sm:h-[400px] md:mb-5 mb-10'>
        <div className='text-transparent bg-gradient-to-r from-[#043060] to-[#0581CE] rounded-3xl md:w-[49%] w-full mb-5 md:mb-0'>
          <div
            style={{ backgroundImage: `url(${playNowBg})` }}
            className='w-full h-full bg-cover bg-no-repeat'
          >
            {
            initloading?
            <p className='white z-20'>Loading</p>
            :
            gameofDay ? (
              <>
                <div className=' w-full lg:w-full lg:h-full md:w-full md:h-full h-[210px] md:px-4 px-4 lg:py-16 lg:px-10 justify-between flex items-center'>
                  <div className='flex justify-center bg-white rounded-full border-8 border-i lg:h-[192px] xl:h-[266.6px] md:h-[150px] lg:w-[192px] xl:w-[264.63px] md:w-[150px] h-32 w-32'>
                    <img
                      className='object-scale-down'
                      src={
                        gameofDay?.product.image ===
                        'c32f09c4b7014b1eb81158aeb9f08623.jpg'
                          ? sokoo
                          : gameofDay?.product.image ===
                            '11ebd7ea44474a00b92f6a715fd82d75.jpg'
                          ? dromo
                          : gameofDay?.product.image ===
                            '02782d2986724068955e83d424d576fc.jpg'
                          ? twadi
                          : gameofDay?.product.image ===
                            'e036100c40f548a4b09b120cc17f5ea1.jpg'
                          ? happy
                          : gameofDay?.product.image ===
                            '13c38e6ee09e44759ccc41272dc14a3e.jpg'
                          ? bingo
                          : gameofDay?.product.image ===
                            '21c342244576481f8eaf2805efd0f478.jpg'
                          ? flow
                          : gameofDay?.product.image ===
                            'af5fdac5eda944b5aafd8429e1648fd5.jpg'
                          ? cashout
                          : ''
                      }
                      alt='Game of Day'
                    />
                  </div>
                  <div className=' items-center text-white flex flex-col justify-center'>
                    {disableGame ? (
                      <h3 className=' lg:text-[32px] xl:text-[54px] xl:leading-[50px] text-3xl font-semibold text-center font-sans uppercase mb-3'>
                        Next <br /> Game
                      </h3>
                    ) : (
                      <h3 className=' lg:text-[32px] xl:text-[54px] xl:leading-[50px] text-3xl font-semibold font-tommy uppercase mb-3'>
                        Game of <br /> the day
                      </h3>
                    )}
                    <Timer disableGame={disableGame} />
                    {/* <p>Draws in </p> */}
                    {/* {console.log(disableGame())} */}
                    {disableGame ? null : (
                      <Link to='game'>
                        <button className='text-white lg:w-[190px] xl:w-[258.33px] py-3 px-6 mt-4 bg-gradient-to-r from-[#FFAC00] to-[#FF4100] font-bold font-tommy text-[16px] xl:text-[24px] h-18  uppercase rounded-3xl xl:rounded-[30px]'>
                          play now!
                        </button>
                      </Link>
                    )}
                  </div>
                </div>
              </>
            ) : (
              <div className='text-white pt-20 mt-6 font-tommy font-bold  text-2xl text-center '>
                {' '}
                Sorry there is <br />
                no game available at the moment
              </div>
            )}
          </div>
        </div>

        <div className=' text-transparent bg-gradient-to-r from-[#FFAC00] to-[#FF4100] rounded-3xl md:w-[49%] w-full'>
          <div
            style={{ backgroundImage: `url(${resultStars})` }}
            className='w-full h-full bg-cover bg-no-repeat flex flex-col items-center justify-center'
          >
            {data?.last_game[0] ? (
              <>
                <div className=' w-full lg:w-full lg:h-full md:w-full md:h-full h-[210px] md:px-4 px-4 lg:py-8 lg:px-4 justify-between flex flex-col items-center'>
                  <div className='flex flex-row items-center justify-between w-full xl:px-5 lg:mt-0 md:mt-0 mt-6 '>
                    <div className=''>
                      <img
                        className='object-contain w-[58px] h-[78px] xl:h-[108.62px] xl:w-[81.16px]'
                        src={alarm}
                        alt='Game Over'
                      />
                    </div>
                    <div>
                      <div className=''>
                        <h3 className='font-bold lg:text-[30px] xl:text-[44px] md:text-[20px] text-[18px] text-white font-tommy uppercase'>
                          Last Draw - {data?.last_game[0].product.name}
                        </h3>
                        <div className='text-white xl:mt-2'>
                          {formatDate(data?.last_game[0].schedule_end)}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className=''>
                    <div className='relative flex flex-row -mt-24 lg:-mt-20 xl:-mt-36'>
                      {data?.last_game[0].results !== undefined ? (
                        <>
                          {data?.last_game[0].results.map(item => (
                            <div
                              key={item}
                              className=' flex shadow-Result-Tile-Shadow items-center justify-center lg:mx-3 mx-1 lg:h-[48px] lg:w-[48px] xl:h-[66.58px] xl:w-[66.58px] h-[30px] w-[30px] border-white border-2 rounded-lg bg-transparent'
                            >
                              <p className=' text-white lg:text-2xl text-lg font-tommy'>
                                {item}
                              </p>
                            </div>
                          ))}{' '}
                        </>
                      ) : (
                        <div className='text-center'>Sorry no previous results</div>
                      )}
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <div className='text-white capitalize flex justify-center items-center  font-tommy font-bold  text-3xl '>
                Sorry no previous results
              </div>
            )}
          </div>
        </div>
      </div>
      {/* <div className='w-11/12 pb-6 mx-auto'>
        <h1 className=' text-3xl font-tommy text-[#043060]'>Games</h1>
      </div> */}
      <div className='mx-auto justify-center grid lg:grid-cols-3 xl:grid-cols-4 grid-cols-2 lg:gap-16 gap-3'>
        {loading ? <div>loading</div> : ''}
        {productsData
          ? productsData?.map(item => (
              <div
                key={item.id}
                className={
                  gameofDay?.product.image === item.image
                    ? 'hidden'
                    : 'flex flex-col border-[#FF7300] border-2 overflow-hidden rounded-[12%] hover:shadow-xl transition-all lg:px-10 lg:py-10 px-0 py-0'
                }
              >
                <div className='flex-shrink-0'>
                  <img
                    className='h-48 w-full p-4 object-contain'
                    src={
                      item.image === 'c32f09c4b7014b1eb81158aeb9f08623.jpg'
                        ? sokoo
                        : item.image === '11ebd7ea44474a00b92f6a715fd82d75.jpg'
                        ? dromo
                        : item.image === '02782d2986724068955e83d424d576fc.jpg'
                        ? twadi
                        : item.image === 'e036100c40f548a4b09b120cc17f5ea1.jpg'
                        ? happy
                        : item.image === '13c38e6ee09e44759ccc41272dc14a3e.jpg'
                        ? bingo
                        : item.image === '21c342244576481f8eaf2805efd0f478.jpg'
                        ? flow
                        : item.image === 'af5fdac5eda944b5aafd8429e1648fd5.jpg'
                        ? cashout
                        : ''
                    }
                    alt='Game'
                  />
                </div>
                {/* <div className='flex flex-1 flex-col justify-between bg-white p-6'>
                  <div className='flex-1'>
                    <p className='text-md font-bold font-tommy '>
                      <span className='hover:underline'>{item.slogan}</span>
                    </p>
                    <div className='mt-2 block'>
                      <p className='text-xl font-semibold font-tommy text-gray-900'>
                        {item.name}
                      </p>

                    </div>
                  </div>
                  <div className='mt-6 flex items-center'>
                    <div className='ml-3'>
                      <p className='text-sm font-medium text-gray-900'></p>
                      <span className=' text-sm mr-2 font-semibold font-tommy text-gray-500'>
                        Every
                      </span>
                      <span className='inline-flex items-center rounded-full bg-blue-100 px-2.5 py-0.5 text-xs font-medium mt-3 text-blue-800'>
                        {item.schedule_day[0] === 'mon' ? (
                          <p>Monday</p>
                        ) : item.schedule_day[0] === 'tue' ? (
                          <p>Tuesday</p>
                        ) : item.schedule_day[0] === 'wed' ? (
                          <p>Wednesday</p>
                        ) : item.schedule_day[0] === 'thu' ? (
                          <p>Thursday</p>
                        ) : item.schedule_day[0] === 'fri' ? (
                          <p>Friday</p>
                        ) : item.schedule_day[0] === 'sat' ? (
                          <p>Saturday</p>
                        ) : item.schedule_day[0] === 'sun' ? (
                          <p>Sunday</p>
                        ) : (
                          ''
                        )}
                      </span>
                    </div>
                  </div>
                </div> */}
              </div>
            ))
          : null}
      </div>
    </div>
  )
}

export default DashHome
