import React, { Fragment, useEffect, useState } from 'react'
import { motion } from 'framer-motion'
import { useQuery, gql, useLazyQuery } from '@apollo/client'
import dromo from '../assets/images/games/dromo.png'
import bingo from '../assets/images/games/bingo.png'
import happy from '../assets/images/games/mrhappy.png'
import flow from '../assets/images/games/overflow.png'
import sokoo from '../assets/images/games/sokooo.png'
import twadi from '../assets/images/games/twadi.png'
import cashout from '../assets/images/games/CASHOUT.png'
import { useFormik } from 'formik'
import dayjs from 'dayjs'

import { Listbox } from '@headlessui/react'
import { HiChevronDown } from 'react-icons/hi2'
import { useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet'

// import MultiSelect from '../components/MultiSelect'
// import ButtonGroup from '../components/ButtonGroup'

// function classNames (...classes) {
//   return classes.filter(Boolean).join(' ')
// }

const GET_LAST_GAME = gql`
  query getLastGame {
    last_game: game(
      limit: 1
      order_by: { closed_at: desc }
      where: {
        is_active: { _eq: false }
        closed_at: { _is_null: false }
        results: { _neq: [] }
      }
    ) {
      id
      results
      schedule_start
      schedule_end
      product {
        id
        name
        image
      }
    }
  }
`

const  GET_RESULTS_HISTORY = gql`
  query resultsHistory($where: game_bool_exp) {
    game(order_by: { schedule_end: desc }, where: $where) {
      event_code
      schedule_end
      results
      product {
        name
      }
    }
  }
`

const options = [
  { value: 'a20092e5-026f-424d-960e-556b806a77fb', label: 'Dromo' },
  { value: '7f5d1c38-d5db-4484-95fc-8cd6a1f33a25', label: 'Twa Di' },
  { value: '2263c7d8-ebe5-4248-8011-4206a5348bcb', label: 'Bingo Bash' },
  { value: '053a654e-6093-495d-b11b-b2ee5d3d8d55', label: 'Mr Happy' },
  { value: '1f56d0c7-0dc0-46f0-b7b8-3e56732011ba', label: 'Overflow' },
  { value: 'dc984a46-f3db-4bd6-b935-95ccec1e6509', label: 'Cash Out' },
  { value: '51526846-ffa0-454c-b9b6-bbacd5cd7041', label: 'Sokooo' }
]

const Results = () => {
  const [activeButton, setActiveButton] = useState(1)
  const [gameResultsHistory, setGameResultsHistory] = useState(null)
  const [getResultsHistory] = useLazyQuery(GET_RESULTS_HISTORY)

  const navigate = useNavigate()

  const today = new Date() // current date

  const [getThisWeekHistory] = useLazyQuery(GET_RESULTS_HISTORY)

  const formik = useFormik({
    initialValues: {
      _gte: '',
      _lte: '',
      _in: []
    },

    onSubmit: values => {
      // console.log(values)
    },

    //Re-running the query anytime there is a change in value
    onChange: () => {
      getResultsHistory({
        variables: {
          where: {
            closed_at: { _is_null: false },
            termintated: { _eq: false },
            results: { _is_null: false },
            _and: [
              { schedule_end: { _gte: formik.values._gte } },
              { schedule_end: { _lte: formik.values._lte } }
            ],
            product: {
              id: {
                _in: formik.values._in
              }
            }
          }
        }
      })
    }
  })
  const [gameOfDay, setGameOfDay] = useState('')
  const { data } = useQuery(GET_LAST_GAME, {
    onCompleted: data => {
      const today = new Date() // current date

      // Calculate the beginning of the current week
      const beginningOfWeek = new Date(today.getTime())
      beginningOfWeek.setDate(
        beginningOfWeek.getDate() - beginningOfWeek.getDay()
      )

      formik.setFieldValue('_gte', dayjs(beginningOfWeek).format('YYYY-MM-D'))
      formik.setFieldValue('_lte', dayjs(today).format('YYYY-MM-D'))

      let updateArr = [...formik.values._in]
      updateArr = [...updateArr, data.last_game[0].product.id]
      formik.setFieldValue('_in', updateArr)

      setGameOfDay(data.last_game[0].product.id)

      // console.log(data.last_game[0].product.id)
    }
  })

  // console.log(data)

  const toggleOption = value => {
    let updateArr = [...formik.values._in]

    if (updateArr.includes(value)) {
      updateArr = updateArr.filter(option => option !== value)
      formik.setFieldValue('_in', updateArr)

      // console.log('removing element', updateArr)
    } else {
      updateArr = [...updateArr, value]
      formik.setFieldValue('_in', updateArr)

      // console.log('Adding element', updateArr)
    }
  }

  //Getting the week's result history the moment the game of the day is set
  useEffect(() => {
    if (gameOfDay !== '') {
      getThisWeekHistory({
        variables: {
          where: {
            closed_at: { _is_null: false },
            termintated: { _eq: false },
            results: { _is_null: false },
            _and: [
              { schedule_end: { _gte: formik.values._gte } },
              { schedule_end: { _lte: formik.values._lte } }
            ],
            product: {
              id: {
                _in: formik.values._in
              }
            }
          }
        },
        onCompleted: resultsHistory => {
          // console.log(resultsHistory)
          setGameResultsHistory(resultsHistory.game)
        },
        onError: error => {
          console.log(error)
        }
      })
    }
  }, [
    gameOfDay,
    formik.values._gte,
    formik.values._lte,
    formik.values._in,
    getThisWeekHistory
  ])

  //Getting results history on Change of values on the formik form
  useEffect(() => {
    getResultsHistory({
      variables: {
        where: {
          closed_at: { _is_null: false },
          termintated: { _eq: false },
          results: { _is_null: false },
          _and: [
            { schedule_end: { _gte: formik.values._gte } },
            { schedule_end: { _lte: formik.values._lte } }
          ],
          product: {
            id: {
              _in: formik.values._in
            }
          }
        }
      },

      onCompleted: res => {
        // console.log(res)
        setGameResultsHistory(res.game)
      },
      onError: error => {
        console.log(error)
      }
    })
  }, [
    getResultsHistory,
    formik.values._gte,
    formik.values._in,
    formik.values._lte
  ])

  const handleButtonClick = buttonNumber => {
    setActiveButton(buttonNumber)
    // Do something else when the button is pressed

    if (buttonNumber === 1) {
      const today = new Date() // current date

      const firstDayOfWeek = new Date(
        today.setDate(today.getDate() - today.getDay())
      ) // first day of the week
      const lastDayOfWeek = new Date(
        today.setDate(today.getDate() - today.getDay() + 6)
      ) // last day of the week

      formik.setFieldValue('_gte', dayjs(firstDayOfWeek).format('YYYY-MM-D'))
      formik.setFieldValue('_lte', dayjs(lastDayOfWeek).format('YYYY-MM-D'))

      // console.log(dayjs(beginningOfWeek).format('YYYY-MM-D'))
      // console.log(dayjs(today).format('YYYY-MM-D'))
    } else if (buttonNumber === 2) {
      const today = new Date() // current date
      const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1) // first day of the month
      const lastDayOfMonth = new Date(
        today.getFullYear(),
        today.getMonth() + 1,
        0
      ) // last day of the month

      formik.setFieldValue('_gte', dayjs(firstDayOfMonth).format('YYYY-MM-D'))
      formik.setFieldValue('_lte', dayjs(lastDayOfMonth).format('YYYY-MM-D'))
    } else if (buttonNumber === 3) {
      const today = new Date() // current date

      const firstDayOfPreviousMonth = new Date(
        today.getFullYear(),
        today.getMonth() - 1,
        1
      ) // first day of the previous month
      const lastDayOfCurrentMonth = new Date(
        today.getFullYear(),
        today.getMonth() + 1,
        0
      ) // last day of the current month
      formik.setFieldValue(
        '_gte',
        dayjs(firstDayOfPreviousMonth).format('YYYY-MM-D')
      )
      formik.setFieldValue(
        '_lte',
        dayjs(lastDayOfCurrentMonth).format('YYYY-MM-D')
      )
    } else {
      const today = new Date() // current date
      const firstDayOfYear = new Date(today.getFullYear(), 0, 1) // first day of the current year
      const lastDayOfYear = new Date(today.getFullYear(), 11, 31) // last day of the current year

      formik.setFieldValue('_gte', dayjs(firstDayOfYear).format('YYYY-MM-D'))
      formik.setFieldValue('_lte', dayjs(lastDayOfYear).format('YYYY-MM-D'))
    }
  }

  // console.log(data)
  return (
    <motion.div
      // initial={{ opacity: 0 }}
      // animate={{ opacity: 1 }}
      // transition={{ duration: 0.2, ease: "easeInOut" }}
      // exit={{ opacity: 0 }}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className=' text-black bg-gray-50 py-32 flex flex-col items-center'
    >
      {process.env.REACT_APP_ENVIRONMENT_NAME === 'PRODUCTION' ? (
        <Helmet>
          <title>Results</title>
          <meta
            name='description'
            content='Page that shows the results for the previous games of Mega Fortune Lottery'
          />
        </Helmet>
      ) : null}
      <div className=' bg-gradient-to-b from-[#FF4100] to-[#FFAC00] mt-16 max-w-[1030px] mx-auto md:w-9/12 items-center rounded-2xl'>
        <div className='-mt-12 flex items-center border-2 border-[#FF4100] bg-white w-28 h-28 mx-auto rounded-full'>
          <img
            className=' h-14 object-contain w-full'
            src={
              data?.last_game[0].product.image ===
              'c32f09c4b7014b1eb81158aeb9f08623.jpg'
                ? sokoo
                : data?.last_game[0].product.image ===
                  '11ebd7ea44474a00b92f6a715fd82d75.jpg'
                ? dromo
                : data?.last_game[0].product.image ===
                  '02782d2986724068955e83d424d576fc.jpg'
                ? twadi
                : data?.last_game[0].product.image ===
                  'e036100c40f548a4b09b120cc17f5ea1.jpg'
                ? happy
                : data?.last_game[0].product.image ===
                  '13c38e6ee09e44759ccc41272dc14a3e.jpg'
                ? bingo
                : data?.last_game[0].product.image ===
                  '21c342244576481f8eaf2805efd0f478.jpg'
                ? flow
                : data?.last_game[0].product.image ===
                  'af5fdac5eda944b5aafd8429e1648fd5.jpg'
                ? cashout
                : ''
            }
            alt='Game of Day'
          />
        </div>
        <div className='py-6 flex items-center justify-center '>
          <div className=' text-white flex lg:gap-8 md:gap-6 gap-2 px-2 py-4'>
            {data?.last_game ? (
              <>
                {data.last_game[0].results.map(item => (
                  <p
                    key={item}
                    className='flex md:rounded-2xl rounded-full border-4 bg-transparent font-tommy lg:text-6xl md:text-4xl border-white lg:w-24 md:w-20 w-12 py-3 text- items-center justify-center shadow-Result-Tile-Shadow'
                  >
                    {item}
                  </p>
                ))}
              </>
            ) : (
              <>Sorry there are no previous results</>
            )}
            {/* {data &&
          data.last_game[0].results.map((item) => (
            <p
              key={item}
              className="flex rounded-full h-12 w-12 bg-[#FF7300] text- items-center justify-center"
            >
              {item}
            </p>
          ))} */}
          </div>
        </div>
        <div className='flex items-center justify-center pb-6'>
          <button
            onClick={e => {
              e.preventDefault()
              navigate('/login')
            }}
            className='bg-white uppercase font-tommy text-[#FF7300] py-3 px-4 rounded-3xl'
          >
            Play Today's Game
          </button>
        </div>
      </div>

      <div className=' max-w-[1030px] mx-auto py-16 w-screen overflow-x-auto'>
        <div className='flex flex-row lg:justify-between justify-start lg:items-center border-b border-black pb-5'>
          <h2 className=' font-tommy text-3xl lg:mb-0 mb-5 '>
            Recent Results{' '}
          </h2>
          <div className='md:mx-0 mx-2'>
            <Listbox value={formik.values._in} onChange={formik.handleChange}>
              <div className='relative lg:mx-2 mx-0 flex-1'>
                <Listbox.Button className='flex flex-row border border-black rounded-md p-2 flex-1'>
                  <HiChevronDown className='mr-4 mt-1' /> Game
                </Listbox.Button>
                <Listbox.Options className='absolute bg-white rounded-lg shadow-lg py-2 right-0 mt-2 border border-[#1D1D1B] z-50'>
                  {options.map(option => (
                    <Listbox.Option
                      key={option.value}
                      value={option.value}
                      className={({ active, selected }) =>
                        `${active ? 'bg-blue-500 text-white' : ''}
     ${selected ? 'bg-blue-600 font-semibold text-white' : ''}
     cursor-default select-none relative py-2 px-5  flex flex-row justify-between`
                      }
                      // onClick={() => toggleOption(option.value)}
                    >
                      <span
                        className={`${
                          formik.values['_in'].includes(option.value)
                            ? 'font-medium'
                            : 'font-normal'
                        } block truncate mr-10 text-[#4F4C4A]`}
                      >
                        {option.label}
                      </span>
                      <input
                        type='checkbox'
                        checked={formik.values['_in'].includes(option.value)}
                        value={option.value}
                        onChange={() => {
                          toggleOption(option.value)
                        }}
                        className='h-5 ml-5 border-[#FFAB66] bg-[#FF7300] '
                      />
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </div>
            </Listbox>
          </div>
          <div className='flex flex-row w-full'>
            {/* MultiSelect Code */}
            <form
              onSubmit={formik.handleSubmit}
              className='flex flex-row md:w-full md:overflow-x-hidden'
            >
              {/* Button Group Code */}
              <div className='flex flex-1 relative sm:left-2 space-x-2 justify-self-stretch justify-between'>
                <button
                  className={`px-4 py-2 rounded-lg font-bold flex-1 ${
                    activeButton === 1
                      ? ' border-2  border-[#FF7300] bg-[#FF73001A] text-[#FF7300] rounded-lg px-4 py-2'
                      : 'border border-black rounded-lg px-4 py-2'
                  }`}
                  onClick={e => {
                    e.preventDefault()
                    handleButtonClick(1)
                  }}
                >
                  This Week
                </button>
                <button
                  className={`px-4 py-2 rounded-lg font-bold flex-1 ${
                    activeButton === 2
                      ? ' border-2  border-[#FF7300] bg-[#FF73001A] text-[#FF7300]'
                      : 'border border-black rounded-lg px-4 py-2'
                  }`}
                  onClick={e => {
                    e.preventDefault()
                    handleButtonClick(2)
                  }}
                >
                  This Month
                </button>
                <button
                  className={`px-4 py-2 rounded-lg font-bold flex-1 ${
                    activeButton === 3
                      ? ' border-2  border-[#FF7300] bg-[#FF73001A] text-[#FF7300]'
                      : 'border border-black rounded-lg px-4 py-2'
                  }`}
                  onClick={e => {
                    e.preventDefault()
                    handleButtonClick(3)
                  }}
                >
                  Last Month
                </button>
                <button
                  className={`px-4 py-2 rounded-lg font-bold flex-1 ${
                    activeButton === 4
                      ? ' border-2  border-[#FF7300] bg-[#FF73001A] text-[#FF7300]'
                      : 'border border-black rounded-lg px-4 py-2'
                  }`}
                  onClick={e => {
                    e.preventDefault()
                    handleButtonClick(4)
                  }}
                >
                  {(today.getFullYear() - 1).toString() +
                    '/' +
                    today.getFullYear().toString()}
                </button>
              </div>
            </form>
          </div>
        </div>
        <div>
          <div className='px-4 sm:px-6 lg:px-8'>
            <div className='mt-8 flow-root'>
              <div className='-my-2 -mx-4 sm:-mx-6 lg:-mx-8'>
                <div className='inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8 h-52 overflow-y-scroll'>
                  <table className='min-w-full divide-y divide-gray-300  '>
                    <thead className='sticky top-0 z-10 bg-white'>
                      <tr>
                        <th
                          scope='col'
                          className='py-3.5 pl-4 pr-3 text-left text-sm font-tommy text-gray-900 sm:pl-0'
                        >
                          Game
                        </th>
                        <th
                          scope='col'
                          className='py-3.5 px-3 text-left text-sm font-tommy text-gray-900'
                        >
                          Event Number
                        </th>
                        <th
                          scope='col'
                          className='py-3.5 px-3 text-left text-sm font-tommy text-gray-900'
                        >
                          Date
                        </th>
                        <th
                          scope='col'
                          className='py-3.5 px-3 text-left text-sm font-tommy text-gray-900'
                        >
                          Winning Numbers
                        </th>
                        <th
                          scope='col'
                          className='relative py-3.5 pl-3 pr-4 sm:pr-0'
                        >
                          <span className='sr-only'>Edit</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody className='divide-y divide-gray-200'>
                      {gameResultsHistory &&
                        gameResultsHistory?.map((game, key) => (
                          <tr key={key}>
                            <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0'>
                              {game?.product.name}
                            </td>
                            <td className='whitespace-nowrap py-4 px-3 text-sm '>
                              {game?.event_code}
                            </td>
                            <td className='whitespace-nowrap py-4 px-3 text-sm'>
                              {dayjs(game?.schedule_end).format('MMM D, YYYY')}
                            </td>
                            <td className='whitespace-nowrap py-4 px-3 text-sm'>
                              {game?.results.join(' , ').toString()}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  )
}

export default Results
