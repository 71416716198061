import React, { useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
// import { Link } from 'react-scroll'

// import logo from "../assets/images/logos/Mega Fortune Lottery Light.png";
import logo2 from '../assets/images/logos/Mega Fortune Lottery Logo Dark.png'
import { Menu, X, ChevronDown } from 'react-feather'
import LoginButton from './LoginButton'
// import LogoutButton from "./LogOutButton";
import SignUpButton from './SignUp'
import { useEffect } from 'react'
import { trackEvent } from '../utils/GoogleAnalytics'

const Navbar = ({ bg }) => {
  let links = [
    { name: 'Games', link: 'games-home' },
    { name: 'Results', link: 'results' },
    { name: 'How to play', link: 'how-to-play' },
    // { name: "Gallery", link: "gallery" },
    { name: 'Become An Agent', link: 'agent-registration' },
    { name: 'About Us', link: 'about-us' }
    // { name: 'Contact', link: 'contact' }
    // {name: "Support", link: "support"}
  ]
  let [open, setOpen] = useState(false)
  const navigate = useNavigate()
  const redirect = () => {
    if (localStorage.getItem('user')) {
      navigate('/dashboard')
    }
  }
  useEffect(() => {
    redirect()
  })
  return (
    <div className='w-full  fixed top-0 bg-white shadow-lg z-50 left-0'>
      <div className='md:flex items-center justify-between w-10/12 mx-auto bg-transparent py-4 md:px-10 px-7'>
        <div className='text-2xl cursor-pointer flex items-center'>
          <NavLink onClick={() => setOpen(false)} to='/'>
            <img className='h-10' src={logo2} alt='Mega Fortune Lottery Logo' />
          </NavLink>
        </div>

        <div
          onClick={() => setOpen(!open)}
          className='text-3xl absolute right-8 top-6 cursor-pointer lg:hidden'
        >
          {open ? <X /> : <Menu />}
        </div>

        <ul
          className={`lg:flex mr lg:items-center lg:pb-0 pb-12 absolute lg:static lg:z-auto z-[-1] left-0 w-full lg:w-auto lg:pl-0 pl-9  ${
            open
              ? 'top-0 text-center text-2xl pt-28  transition-all duration-300 ease-in bg-white h-screen'
              : 'top-[-490px] transition-all duration-500  text-center ease-out'
          }`}
        >
          {links.map((link, key) => {
            return (
              <>
                {link.name === 'Games' ? (
                  <>
                    <div className='hidden md:block absolute pt-1 xl:left-[46%] lg:left-[31%] z-10 lg:ml-8 sm:hidden font-semibold font-tommy text-sm uppercase lg:my-0 my-7 '>
                      <NavLink
                        onClick={() => {
                          setOpen(false)
                          trackEvent({
                            category: 'Link',
                            action: 'Click',
                            label: `${link.name} was clicked on the Navbar`,
                            value: 6
                          })
                        }}
                        to={link.link}
                        className={({ isActive }) =>
                          isActive
                            ? 'peer border-[#FF7300] border-b-2 flex '
                            : 'peer px-5 py-2 flex '
                        }
                      >
                        GAMES
                        <ChevronDown />
                      </NavLink>
                      <div className='hidden peer-hover:flex hover:flex flex-col bg-white drop-shadow-lg absolute text-start'>
                        <NavLink
                          to={'daily-games'}
                          className='px-5 py-3 border-b-2 hover:bg-gray-200'
                          href='#'
                        >
                          Daily&nbsp;Games
                        </NavLink>
                        <NavLink
                          to={'game-dynamics'}
                          className='px-5 py-3 border-b-2 hover:bg-gray-200'
                          href='#'
                        >
                          Dynamics
                        </NavLink>
                        <NavLink
                          to={'game-pricing'}
                          className='px-5 py-3 border-b-2 hover:bg-gray-200'
                          href='#'
                        >
                          Pricing
                        </NavLink>
                      </div>
                    </div>
                    <li
                      key={key}
                      className='lg:ml-8 sm:hidden font-semibold font-tommy text-sm uppercase lg:my-0 my-7'
                    >
                      <NavLink
                        onClick={() => {
                          setOpen(false)
                          trackEvent({
                            category: 'Link',
                            action: 'Click',
                            label: `${link.name} was clicked on the Navbar`,
                            value: 6
                          })
                        }}
                        to={link.link}
                        className={({ isActive }) =>
                          isActive
                            ? ' border-[#FF7300] border-b-2 transition-all'
                            : 'hover:text-gray-400 '
                        }
                      >
                        {link.name}
                      </NavLink>
                    </li>
                  </>
                ) : (
                  <li
                    key={key}
                    className='lg:ml-8 font-semibold font-tommy text-sm uppercase lg:my-0 my-7'
                  >
                    <NavLink
                      onClick={() => {
                        setOpen(false)
                        trackEvent({
                          category: 'Link',
                          action: 'Click',
                          label: `${link.name} was clicked on the Navbar`,
                          value: 6
                        })
                      }}
                      to={link.link}
                      className={({ isActive }) =>
                        isActive
                          ? ' border-[#FF7300] border-b-2 transition-all'
                          : 'hover:text-gray-400 '
                      }
                    >
                      {link.name}
                    </NavLink>
                  </li>
                )}
              </>
            )
          })}
          {!localStorage.user && (
            <div className='md:pl-6'>
              <LoginButton setOpen={setOpen} />
              {/* <LogoutButton /> */}
              <SignUpButton setOpen={setOpen} />
            </div>
          )}
        </ul>
      </div>
    </div>
  )
}

export default Navbar
